import React, { useContext } from 'react'
import { TabBar } from 'antd-mobile'
import {
    useHistory,
    useLocation,
} from 'react-router-dom'
import {
    UserOutline,
    BankcardOutline,
    StarOutline

} from 'antd-mobile-icons'
import './NavBar.css'
import {AuthContext} from "../../../Auth";
import { isDriverApplicant } from '../../../util/Helpers'
import { FormattedMessage, useIntl  } from "react-intl";

const NavBar = () => {
    const intl = useIntl()
    const history = useHistory();
    const location = useLocation();
    const {authenticated} = useContext(AuthContext)
    const { pathname } = location;

    const setRouteActive = (value) => {
        history.push(value)
    };

    const tabs = [
        {
            key: '/profile',
            title: <FormattedMessage id="app.profile"/>,
            icon: <UserOutline />,
        },
        {
            key: '/payments',
            title: <FormattedMessage id="app.payments"/>,
            icon: <BankcardOutline />,
        },
        {
            key: '/rewards',
            title: <FormattedMessage id="app.rewards"/>,
            icon: <StarOutline />,
        }
    ];

    if (!authenticated || (authenticated && isDriverApplicant())) return '';

    return (
        <TabBar className={"tab-bar"} activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}

export default NavBar
