import {Import} from "./Import";
import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import './App.css';
import locale_en from "./translations/en.json";
import locale_fr from "./translations/fr.json";
import { useLocalStorage } from './hooks/useLocalStorage';
import {IntlProvider} from "react-intl";
import { Layout } from 'antd';
import Div100vh from 'react-div-100vh';
import jwtDecode from "jwt-decode";
import { AuthProvider } from './Auth';
import NavBar from "./components/touch/common/NavBar";
import { getLoggedInDriverRoles } from "./util/Helpers";

const { Content } = Layout;

const isMobile = window.matchMedia("(pointer: coarse) and (hover: none)").matches;

const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) {
        return false;
    }
    const decodedToken = jwtDecode(token);
    const bufferTime = 40 * 60; // time that the client can sit idle in s
    return !(decodedToken.exp * 1000 <= Date.now() + bufferTime * 1000 || !token);

};

const DynamicRoute = ({path, component, onChangeLanguage, locale, privateRoute, driverRoles}) => {
    if (privateRoute) {
        if (!isAuthenticated()) {
            component = 'Login'
        }
        else {
            const loggedInUserRoles = getLoggedInDriverRoles();
            if (!loggedInUserRoles.every(val => driverRoles.includes(val))) {
                component = 'Login'
            }
        }
    }
    return (
        <Import
            touch={() => import(`./components/touch/${component}`)}
            desktop={() => import(`./components/desktop/${component}`)}
            isMobile={isMobile}
        >
            {Comp =><Route exact path={path} render={props => <Comp {...props} onChangeLanguage={onChangeLanguage} locale={locale}/>}/>}
        </Import>
    )
};

const DynamicCommonComponent = ({component, onChangeLanguage, locale}) => {
    return (
        <Import
            touch={() => import(`./components/touch/common/${component}`)}
            desktop={() => import(`./components/desktop/common/${component}`)}
            isMobile={isMobile}
        >
            {Comp => <Comp onChangeLanguage={onChangeLanguage} locale={locale}/>}
        </Import>
    )
}

const App = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries())

    const [locale, setLocale] = useLocalStorage('lang', 'en')
    let myLocale = params.locale === 'en' || params.locale === 'fr'? params.locale : null
    if (myLocale && (locale !== myLocale)) {
        setLocale(myLocale)
    }

    const data = {
        'fr': locale_fr,
        'en': locale_en
    };

    const onChangeLanguage = (event) => {
        let lang = event;
        switch (lang) {
            case "en":
                setLocale("en");
                break;
            case "fr":
                setLocale("fr");
                break;
            default:
                setLocale("en");
                break;
        }
    };
  return (
      <IntlProvider key={locale} locale={locale} messages={data[locale]}>
          <Layout className="App">
              <Content className={"app-content"}>
                  <Div100vh className={"container"}>
                      <AuthProvider>
                              <BrowserRouter>
                                  <div className={"top"} style={{position: 'sticky'}}>
                                      <DynamicCommonComponent component="AppHeader" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={false}/>
                                  </div>
                                  <Switch>
                                        <DynamicRoute exact path="/" component="Login" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={false}/>
                                      
                                        <DynamicRoute exact path="/profile" component="Profile" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driver']}/>
                                        <DynamicRoute exact path="/profile/updateAddress" component="UpdateAddress" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driver']}/>
                                        <DynamicRoute exact path="/payments" component="Payments" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driver']}/>
                                        <DynamicRoute exact path="/payments/updateBanking" component="UpdateBanking" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driver']}/>
                                        <DynamicRoute exact path="/rewards" component="Tickets" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driver']}/>

                                        <DynamicRoute exact path="/apply" component="Apply" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={false} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/verifyEmail" component="VerifyEmail" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={false} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/hub" component="ApplyHub" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/tellUs" component="TellUs" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/address" component="Address" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/vehicle" component="Vehicle" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/weeklyAvailabilities" component="WeeklyAvailabilities" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/requirementsVerification" component="RequirementsVerification" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/driversLicense" component="DriversLicense" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/quiz" component="Quiz" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                        <DynamicRoute exact path="/apply/tutorial" component="Tutorial" onChangeLanguage={onChangeLanguage} locale={locale} privateRoute={true} driverRoles={['driverApplicant']}/>
                                  </Switch>
                                  {isMobile &&
                                  <div className={"bottom"}>
                                      <   NavBar />
                                  </div>}
                              </BrowserRouter>
                      </AuthProvider>
                  </Div100vh>
              </Content>
          </Layout>
      </IntlProvider>
  );
};

export default App;
