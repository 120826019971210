import { useState, useEffect } from "react";

export const Import = ({ touch, desktop, children, isMobile }) => {
    const [Component, setComponent] = useState(null);

    useEffect(() => {
        console.log(isMobile)
        // Assign a callback with an import() call
        const importCallback = isMobile ? touch : desktop;

        // Executes the 'import()' call that returns a promise with
        // component details passed as an argument
        importCallback().then(componentDetails => {
            // Set the import data in the local state
            setComponent(componentDetails);
        });
    }, [desktop, touch]);

    // The actual component is assigned to the 'default' prop
    return children(Component ? Component.default : () => null);
}