import jwtDecode from "jwt-decode";
import rbc from "../static/images/rbc.png";
import bmo from "../static/images/bmo2.png";
import scotiabank from "../static/images/scotiabank.png";
import td from "../static/images/td.png";
import national from "../static/images/national.png";
import cibc from "../static/images/cibc.png";
import desjardins from "../static/images/desjardins.png";
import laurentian from "../static/images/laurentian.jpeg";
import presidentschoice from "../static/images/presidentschoice.png";
import tangerine from "../static/images/tangerine.jpeg";
import React from "react";
import BankFilled from "@ant-design/icons/es/icons/BankOutlined";
import { FormattedMessage  } from "react-intl"

export function checkAuth() {
    const token = localStorage.getItem("token");
    console.log(token)

    let exp = false;
    if (!token) {
        return {decodedToken: null, expired: exp}
    }
    const decodedToken = jwtDecode(token);
    const bufferTime = 40 * 60; // time that the client can sit idle in s
    if (decodedToken.exp * 1000 <= Date.now() + bufferTime * 1000 || !token) {
        exp = true
    }
    return {decodedToken: decodedToken, expired: exp}
}

export function isAuthenticated() {
    const {decodedToken, expired} = checkAuth()
    return decodedToken && !expired
}

export function isDriverApplicant() {
    const {decodedToken, expired} = checkAuth()
    return decodedToken && decodedToken.driverRoles != null && decodedToken.driverRoles.includes('driverApplicant') && !expired
}

export function isDriver() {
    const {decodedToken, expired} = checkAuth()
    return decodedToken && decodedToken.driverRoles == null && !expired
}

export function getDriverApplicantEmail() {
    const {decodedToken, expired} = checkAuth()
    return decodedToken? decodedToken.email : null
}

export function getLoggedInDriverRoles() {
    if (!isAuthenticated()) return []
    if (isDriver()) return ['driver']
    const {decodedToken, expired} = checkAuth()
    return !expired && decodedToken? decodedToken.driverRoles : []
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export function downloadPDF(file, fileName) {
    const linkSource = `${file}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const institutionToBankHelper = (institution, desktop = false) => {
    let result = {
        name: null,
        logo: null,
        color: "#000000"
    };

    switch(institution) {
        case "001":
            result.name = "Bank Of Montreal";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={bmo}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#0078C1";
            break;
        case "002":
            result.name = "Scotiabank";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={scotiabank}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#EE1229"
            break;
        case "003":
            result.name = "Royal Bank Of Canada";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={rbc}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#005DAA";
            break;
        case "004":
            result.name = "TD Canada Trust";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={td}
                alt="BANK"
                style={{width: desktop? "70%":"90%"}}
                color="#FFFFFF"
            />;
            result.color = "#35B234";
            break;
        case "006":
            result.name = "National Bank Of Canada";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={national}
                alt="BANK"
                style={{width: "90%"}}
                color="#FFFFFF"
            />;
            result.color = "#e41c23";
            break;
        case "010":
            result.name = "CIBC Bank";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={cibc}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#BA0034";
            break;
        case "815":
            result.name = "Caisses Desjardins du Québec";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={desjardins}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#009757";
            break;
        case "039":
            result.name = "Laurentian Bank of Canada";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={laurentian}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#FDAF00";
            break;
        case "320":
            result.name = "President's Choice Bank";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={presidentschoice}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#D9000A";
            break;
        case "614":
            result.name = "Tangerine Bank";
            result.logo = <img
                back = {"Back"}
                key={"1"}
                src={tangerine}
                alt="BANK"
                style={{height: "100%"}}
                color="#FFFFFF"
            />;
            result.color = "#E56210";
            break;
        default:
            result.name = <FormattedMessage id="app.updateBanking.yourInstitutionName"/>;
            result.logo = <BankFilled style={{fontSize: "5vh"}} />
            break;
    }
    return result;
}


export function updateQueryStringParameter(uri, key, value) {
    value = encodeURIComponent(value)
    // remove the hash part before operating on the uri
    var i = uri.indexOf('#');
    var hash = i === -1 ? ''  : uri.substr(i);
    uri = i === -1 ? uri : uri.substr(0, i);

    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";

    if (value === null) {
        // remove key-value pair if value is specifically null
        uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
        if (uri.slice(-1) === '?') {
            uri = uri.slice(0, -1);
        }
        // replace first occurrence of & by ? if no ? is present
        if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
    } else if (uri.match(re)) {
        uri = uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        uri = uri + separator + key + "=" + value;
    }
    return uri + hash;
}

export const GOOGLE_MAP_STYLES_DARK = [
    {
        "featureType": "all",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#242f3e"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#746855"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#242f3e"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#d59563"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#d59563"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#263c3f"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6b9a76"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#38414e"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#212a37"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9ca5b3"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#746855"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#1f2835"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#f3d19c"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#2f3948"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#d59563"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#17263c"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#515c6d"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#17263c"
            }
        ]
    }
]


export const GOOGLE_MAP_STYLES = [
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#d6e2e6"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#cfd4d5"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7492a8"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": 25
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#cfd4d5"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7492a8"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#dde2e3"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#588ca4"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a9de83"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bae6a1"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c6e8b3"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bae6a1"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#41626b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -45
            },
            {
                "lightness": 10
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c1d1d6"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#a6b5bb"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#9fb6bd"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "saturation": -70
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#b4cbd4"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#588ca4"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#008cb5"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": -5
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a6cbe3"
            }
        ]
    }
]


export const downloadFile = (v) => {
    const linkSource = v.url
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = v.name;
    downloadLink.click(); 
}

export const readUploadedFileAsText = (inputFile, side = null) => {
    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      temporaryFileReader.onload = async () => {
            console.log(temporaryFileReader.result)
            let obj = {
                type: inputFile.type,
                contents: temporaryFileReader.result
            }
            if (side != null) obj['side'] = side
            resolve(obj)
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
};

export const arrangeData = async (data, side = null) => {

    if (data[0].contents != null) {
        let obj = {
            type: data[0].type,
            contents: data[0].contents
        }
        if (side != null) obj['side'] = side
        
        return obj
    }
    else {
        let promises = data.map(f => {
            return readUploadedFileAsText(f.originFileObj, side)
        })

        let files = await Promise.all(promises)
        return files[0]
    }
}