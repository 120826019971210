import React, { useEffect, useState } from "react";
import { isAuthenticated, isDriverApplicant } from "./util/Helpers";

export const AuthContext = React.createContext({
    authenticated:false, 
    setAuthenicated: () => {},
    driverApplicant: false, 
    setDriverApplicant: () => {},
});

export const AuthProvider = ({ children }) => {
    const [pending, setPending] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [driverApplicant, setDriverApplicant] = useState(false);

    useEffect(() => {
        setAuthenticated(isAuthenticated())

        setDriverApplicant(isDriverApplicant())

        setPending(false)
    }, []);

    if(pending){
        return <>Loading...</>
    }

    return (
        <AuthContext.Provider value={{authenticated, setAuthenticated:setAuthenticated}}>
            {children}
        </AuthContext.Provider>
    );
};